import React from 'react';
import cls from './Apps.module.scss'
import {classNames} from "src/shared/lib/classNames/classNames";
import {AppText} from "src/shared/ui/AppText";
import {AppTextTheme} from "src/shared/ui/AppText/ui/AppText";
import AppleImage from 'src/shared/assets/img/apps/img/apple.png'
import GooglePlay from 'src/shared/assets/img/apps/img/googleplay.png'
import ApkImage from 'src/shared/assets/img/apps/img/apk.svg'
import {APK_LINK, APP_STORE_LINK, GOOGLE_PLAY_LINK} from "src/shared/const/markets";
import {Link} from "react-router-dom";

interface AppsProps {
    className?: string
}

export const Apps = (props: AppsProps) => {
    const { className = '' } = props

    const appsList = [
        {
            icon: AppleImage,
            title: 'AppStore',
            link: APP_STORE_LINK
        },
        {
            icon: GooglePlay,
            title: 'Google Play',
            link: GOOGLE_PLAY_LINK
        },
        {
            icon: ApkImage,
            title: 'APK',
            link: APK_LINK
        }
    ]

    return (
        <div className={classNames(cls.apps, {}, [className])}>
            {
                appsList.map((item) => (
                    <Link key={item.title} to={item.link} target={'_blank'} className={cls.appsItem}>
                        <img src={item.icon} alt="Icon"/>
                        <AppText
                            text={item.title}
                            fontSize={18}
                            fontWeight={600}
                            theme={AppTextTheme.BLACK}
                        />
                    </Link>
                ))
            }

        </div>
    );
};

