import React from 'react';
import {classNames} from "src/shared/lib/classNames/classNames";
import cls from "./TermsPrivacyPage.module.scss";
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";
import {AppText} from "src/shared/ui/AppText";
import {AppTextTag, AppTextTheme} from "src/shared/ui/AppText/ui/AppText";
import {Logo} from "src/widgets/Logo";
import {EMAIL, SITE_LINK} from "src/shared/const/links";

export const TermsPrivacyPage = () => {
    return (
        <section className={classNames(cls.termsOfUse, {}, [])}>
            <Helmet>
                <title>{'Terms, Rules and Privacy Policy of the Application'}</title>
                <meta name="description" content={'Before using the move to earn app UMI Walk on web3 token UMI, please read the Terms, Rules and Privacy Policy carefully '} />
                <meta name="keywords" content={'move to earn, web 3, UMI walk, UMI token'} />
            </Helmet>
            <Logo height={'100px'} />
            <AppText
                className={cls.termsOfUseTitle}
                text={'Terms, Conditions, Risks and Privacy Policy of UMI Walk'}
                fontSize={40}
                fontWeight={700}
                lineHeight={40}
            />
            <div className={cls.termsOfUseContainer}>
                <div className={cls.termsOfUseBlock}>
                    <AppText
                        className={cls.termsOfUseTitleSecondary}
                        text={'1.Terms, conditions and risks of UMI Walk'}
                        fontSize={32}
                        fontWeight={700}
                        lineHeight={32}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'We welcome you (hereinafter referred to as "User" or "you" including "your"/"yourselves" etc.) to the UMI Walk mobile gaming app (hereinafter referred to as "UMI Walk", "App" or "we" (including "our"/"ours" etc.)!'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                </div>
                <div className={cls.termsOfUseBlock}>
                    <AppText
                        className={cls.termsOfUseTitleThird}
                        text={'Brief Information about the Application'}
                        fontSize={20}
                        fontWeight={700}
                        lineHeight={24}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'The UMI Walk app is a mobile game that allows you to earn UMI game coins by walking or running outdoors, working out in a gym or other indoor environment, or by playing a clicker game where you simply click on your smartphone screen. Other games and/or mechanics can also be added in the future. The game algorithm tracks users\' activity and rewards them with special game coins.'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'UMI Walk facilitates people\'s interaction in entertainment and social formats, as well as allows them to monitor their fitness and health.'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                </div>
                <div className={cls.termsOfUseBlock}>
                    <AppText
                        className={cls.termsOfUseTitleThird}
                        text={'Context of these Terms, Conditions and Risks'}
                        fontSize={20}
                        fontWeight={700}
                        lineHeight={24}
                    />
                    <div>
                        <AppText
                            className={cls.termsOfUseText}
                            text={'These Terms, Conditions and Risks are a legal and informational agreement between you and UMI Walk regarding your access to and use of the '}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                            tag={AppTextTag.SPAN}
                        />
                        <Link to={SITE_LINK} target={'_blank'}>
                            <AppText
                                className={cls.termsOfUseText}
                                text={SITE_LINK}
                                fontSize={14}
                                fontWeight={400}
                                lineHeight={20}
                                theme={AppTextTheme.BLUE}
                                tag={AppTextTag.SPAN}
                            />
                        </Link>
                        <AppText
                            className={cls.termsOfUseText}
                            text={' Website and UMI Walk Application, as well as any other websites, applications, social networks and other web resources, in any way related to us.'}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                            tag={AppTextTag.SPAN}
                        />
                    </div>
                    <AppText
                        className={cls.termsOfUseText}
                        text={'Our services are available to Users only upon acceptance of these Terms, Conditions and Risks. By visiting and/or using the Site or App, you agree that you have read, understood and agreed to be bound by all rules, regulations and prohibitions set forth herein. If you do not agree to and/or accept the Terms, Conditions and Risks in whole or in part, you are expressly prohibited from using our services and must immediately cease using them. By continuing to use the Application, you hereby confirm that you agree to the Terms, Conditions and Risks of UMI Walk.'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'You may revoke your agreement to these Terms, Conditions and Risks at any time by providing us with written notice of such revocation to the email address provided in the " Contact Us" section. If you revoke your consent or otherwise cease to be bound, in whole or in part, by these Terms, Conditions and Risks, you will not receive any feedback when you contact us regarding any transactions you may make on the Application Or through third party services.'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                </div>
                <div className={cls.termsOfUseBlock}>
                    <AppText
                        className={cls.termsOfUseTitleThird}
                        text={'User Registration'}
                        fontSize={20}
                        fontWeight={700}
                        lineHeight={24}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'To access the features of the Application, you will need to register an account using your email and password. If you do so, you agree to keep your login and password confidential and are solely responsible for any unauthorized use of your account.In return, we will not be liable for any loss you incur due to loss of access to your account and/or due to the use of your account by third parties.'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                </div>
                <div className={cls.termsOfUseBlock}>
                    <AppText
                        className={cls.termsOfUseTitleThird}
                        text={'Rights and Injunctions on Use'}
                        fontSize={20}
                        fontWeight={700}
                        lineHeight={24}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'Visiting and using the Application is regulated by a number of rules set out below. The User is fully and solely responsible for compliance therewith. UMI Walk, for its part, shall not be liable for the User\'s infringement of these rules.'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'1. If you have not reached full legal age (according to the laws of your country) and are not 18, you must not visit and use the Application. Based on the above, by visiting and using the Application, you confirm that you have reached the required age.'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'2. The Application is not intended for distribution or use in all countries and jurisdictions without exception. This is prohibited if the use of the Application and/or the distribution of any information about UMI Walk contradicts legislation or regulations in any way (directly or even indirectly), or may entail any registration requirements within such jurisdiction or country. In particular, you also warrant and guarantee that you are not a citizen or resident of:'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                    <div className={cls.termsOfUseBlockSecondary}>
                        <AppText
                            className={cls.termsOfUseText}
                            text={'- The United States of America (as indicated below);'}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                        />
                        <AppText
                            className={cls.termsOfUseText}
                            text={'- Other countries where any prohibitions or restrictions (even indirect ones) on visiting and using web resources and applications of the given subject matter (hereinafter - "Prohibited Countries") are in force at the legislative level.'}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                        />
                        <AppText
                            className={cls.termsOfUseText}
                            text={'- Countries that are under any international sanctions, including those related to the injunction on information of any kind.'}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                        />
                    </div>
                    <AppText
                        className={cls.termsOfUseText}
                        text={'Any person who holds citizenship, tax residence or residence permit in the United States of America and other countries with any injunctions mentioned above (hereinafter – the "Prohibited Country" / "Prohibited Countries") has no right to visit and use the Application, as well as to distribute information about it. In view of the above, if you are a citizen, tax resident, temporary or permanent resident of the United States of America or other Prohibited Countries, you must immediately leave and no longer use the Application and refrain from distributing information about it — either independently or with the help of any third parties.'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'Thus, persons who decide to visit and/or use the Application (or distribute information about it) contrary to the above injunctions shall do so on their own initiative and shall be solely responsible for compliance with local legislation to the extent applicable to them. Once again, we urge you to comply with all provisions of these Terms and Rules, as well as all international laws and laws of your respective country of temporary or permanent residence.'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                </div>
                <div className={cls.termsOfUseBlock}>
                    <AppText
                        className={cls.termsOfUseTitleThird}
                        text={'Actions Prohibited in the Application'}
                        fontSize={20}
                        fontWeight={700}
                        lineHeight={24}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'You may not use the Application for any purposes other than those which it was designed for. The Application may not be used for any commercial activities, except in the cases legally agreed with UMI Walk.'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'The prohibited actions in relation to the Application include, but are not limited to, the following:'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                    <div className={cls.termsOfUseBlockSecondary}>
                        <AppText
                            className={cls.termsOfUseText}
                            text={'- Use of any content from the Application to create copies, equivalents, compilations or catalogs without our written consent. Copying or adapting software from the Application, including, but not limited to, HTML, JavaScript or other code.'}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                        />
                        <AppText
                            className={cls.termsOfUseText}
                            text={'- Use of the Application in any unauthorized way, including collection of personal user details (names, email addresses and other data) via electronic or other means for the purpose of sending third-party emails, including spam, or creating user accounts automatically or under false pretenses, among other things.'}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                        />
                        <AppText
                            className={cls.termsOfUseText}
                            text={'- Use of robots to perform any operations in the Application; use of the Application to advertise or offer various goods and services.'}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                        />
                        <AppText
                            className={cls.termsOfUseText}
                            text={'- Bypassing, disabling or otherwise interfering with the security functions of the Application, including functions that prevent or restrict the use or copying of any content of the Application; unauthorized publication of links in the Application.'}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                        />
                        <AppText
                            className={cls.termsOfUseText}
                            text={'- Attempt to impersonate another user of the Application or an authorized UMI Walk representative; sale or other transfer of your personal account to any other person. Harassment, intimidation or threats against UMI Walk users, employees or agents.'}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                        />
                        <AppText
                            className={cls.termsOfUseText}
                            text={'- Misleading our support services, including by sending deliberately false reports of abuse or misconduct; using any automated methods of using the system, such as scripts, to send any messages or using any data mining tools, robots or similar data collection and extraction tools.'}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                        />
                        <AppText
                            className={cls.termsOfUseText}
                            text={'- Interference with the operation of the Application in order to prevent its normal functioning or partially or completely disable it, including by creating excessive load on the Application or on the servers it uses, among other things.'}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                        />
                        <AppText
                            className={cls.termsOfUseText}
                            text={'- Any insults to other users of the Application or causing them any harm.'}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                        />
                        <AppText
                            className={cls.termsOfUseText}
                            text={'- Use of the Application and/or its contents for any commercial undertaking for the purpose of generating income or receiving any other benefits.'}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                        />
                        <AppText
                            className={cls.termsOfUseText}
                            text={'- Decryption, decompilation, disassembly or reverse engineering of any software that includes or in any way forms part of the Application; attempt to circumvent any security mechanisms in the Application designed to prevent or restrict access to the Application or any part thereof.'}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                        />
                        <AppText
                            className={cls.termsOfUseText}
                            text={'- Removal of copyright or other title of ownership notices from any content in the Application or from any derived content.'}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                        />
                        <AppText
                            className={cls.termsOfUseText}
                            text={'- Uploading or transmitting (or attempting to do so) any file infecting viruses or other malicious materials that interfere with the uninterrupted use of the Application or that modify, degrade, disrupt any functions related to the operation or maintenance of the Application.'}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                        />
                        <AppText
                            className={cls.termsOfUseText}
                            text={'- Uploading or transmitting (or attempting to do so) any materials that constitute a passive or active mechanism for collecting or transmitting data, including, but not limited to, formats such as GIF graphics, 1×1 pixels, web bugs, cookies or other similar tools, also known as "spyware", "passive collection mechanisms" or "PCM", among others.'}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                        />
                        <AppText
                            className={cls.termsOfUseText}
                            text={'- Development, use or distribution of any automated systems, fraudulent utilities, parsers, scripts or offline readers, among other things, that can be used to gain unauthorized access to the content of the Application.'}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                        />
                        <AppText
                            className={cls.termsOfUseText}
                            text={'- Causing direct or indirect damage to us and/or the Application. Such damage is determined and assessed at our sole discretion; use of the Application in any manner inconsistent with any applicable laws or regulations.'}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                        />
                        <AppText
                            className={cls.termsOfUseText}
                            text={'- Any actions on the Site and the Application directly or indirectly related to terrorist financing, money laundering, organizing and conducting illegal gambling, among other things, and actions that violate international laws, the laws of your country of residence and/or domicile, as well as the laws of the country where the UMI Walk game is registered.'}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                        />
                        <AppText
                            className={cls.termsOfUseText}
                            text={'- Intentionally taking advantage of technical or technological errors, bugs, glitches and other defects existing on the Site and the Application. If any such factors are detected, the User should immediately report them to UMI Walk.'}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                        />
                    </div>
                    <AppText
                        className={classNames(cls.termsOfUseText, {}, [cls.termsOfUseTextUppercase]) }
                        text={'PLEASE NOTE: YOUR ACCOUNT MAY BE BLOCKED DUE TO VIOLATIONS OF THE TERMS, CONDITIONS AND RISKS, RESULTING IN THE LOSS OF VARIOUS FEATURES OF UMI WALK, INCLUDING LOSS OF ACCESS TO INTERNAL SERVICES AND FEATURES OF THE APPLICATION AND/OR SITE. UMI WALK WILL NOT COMPENSATE YOU FOR THIS LOSS UNDER ANY CIRCUMSTANCES. THEREFORE, BY ACCEPTING THESE TERMS, CONDITIONS AND RISKS, YOU ACKNOWLEDGE AND AGREE THAT IF YOU VIOLATE THESE TERMS, YOU MAY LOSE MANY OR EVEN ALL OF THE FEATURES OF UMI WALK, INCLUDING ALL SERVICES AND FEATURES AVAILABLE ON THE SITE AND APP, WITHOUT ANY RIGHT TO ANY COMPENSATION. YOU FURTHER AGREE THAT ANY SUSPENSION OR TERMINATION OF YOUR ACCESS TO THE SITE AND/OR APPLICATION MAY BE EFFECTED WITHOUT PRIOR NOTICE TO YOU AND THAT WE SHALL NOT BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY SUCH SUSPENSION OR TERMINATION.'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                </div>
                <div className={cls.termsOfUseBlock}>
                    <AppText
                        className={cls.termsOfUseTitleThird}
                        text={'Operations and Consequences'}
                        fontSize={20}
                        fontWeight={700}
                        lineHeight={24}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'The Site and Application reserve the right to use third party services in any way in their operation. If you decide to perform any operation through the Site or Application or for use on the Site or Application (by any means, including simply clicking on the relevant link) using third-party services not directly related to UMI Walk, any operation you decide to perform in this way will require you to comply with their own terms and conditions. For its part, UMI Walk provides only the functionality of operations within the Application/Site. Any third party services are not controlled by UMI Walk.'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'You agree to use any third party services not directly related to UMI Walk at your own risk. You agree not to make any claims against UMI Walk with respect to any third party services. We shall not be liable to you or any third party for any claims or losses you may incur as a result of any transactions you make through third party services.'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'To the fullest extent permitted by applicable law, you agree and acknowledge that we have no obligation to make refunds or other refunds of funds or other assets, including digital or in-game assets on the Site or Application For any incorrect or erroneous transactions or actions/transactions that you may make for any reason, including if they are in no way related to third party services but relate directly to the Site and App.'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'Any requests for refunds or compensation for any loss of any equivalent value incurred by you in connection with your use of the Site or Application will be accepted or rejected at our sole discretion. This includes, but is not limited to, the following circumstances in which events of this nature could potentially occur:'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                    <div className={cls.termsOfUseBlockSecondary}>
                        <AppText
                            className={cls.termsOfUseText}
                            text={'- an accidental or wrong transaction by you.'}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                        />
                        <AppText
                            className={cls.termsOfUseText}
                            text={'- malfunctions or other technical errors occurring on the Site or App.'}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                        />
                        <AppText
                            className={cls.termsOfUseText}
                            text={'- fraudulent acts by third parties against you, prohibited activity or wrongdoing by another user.'}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                        />
                        <AppText
                            className={cls.termsOfUseText}
                            text={'- manipulation of other users that may lead to unreasonable consequences, including in any way relating to game balances in any way.'}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                        />
                        <AppText
                            className={cls.termsOfUseText}
                            text={'- use of the Site or Application in a manner inconsistent with its purpose.'}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                        />
                    </div>
                </div>
                <div className={cls.termsOfUseBlock}>
                    <AppText
                        className={cls.termsOfUseTitleThird}
                        text={'Risks of Using the Application'}
                        fontSize={20}
                        fontWeight={700}
                        lineHeight={24}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'UMI Walk shall not be liable for any risks and claims that Users may have for any reason, including, but not limited to the following:'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                    <div className={cls.termsOfUseBlockSecondary}>
                        <AppText
                            className={cls.termsOfUseText}
                            text={'- Actions of the User, as a result of which this User has lost the ability to correctly and/or fully use the Site and the Application. These include: loss of any data by the User, including loss of login and/or password from the personal account.'}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                        />
                        <AppText
                            className={cls.termsOfUseText}
                            text={'- Issues resulting in the incorrect operation of the Application or any affiliated websites, software or Services. These include: damage to the software, access to insecure libraries, infecting the Application or any affiliated sites, software and Services with malicious code.'}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                        />
                        <AppText
                            className={cls.termsOfUseText}
                            text={'- Technical issues arising on the User\'s side resulting in the User losing access to the Application or any related sites, software or Services. For example, loss of credentials or any other data due to the incorrect operation or complete failure of the device storing this data.'}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                        />
                        <AppText
                            className={cls.termsOfUseText}
                            text={'- Problems related to the security of the User of the Website and Application, resulting from illegal access of third parties to the User\'s personal account and any other confidential data.'}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                        />
                        <AppText
                            className={cls.termsOfUseText}
                            text={'- Any situations or events that can have a direct impact on Users, but at the same time beyond the control of the UMI Walk team. For example, interruption or failure of the service provider, various force majeure circumstances, hacker attacks on the provider\'s network, military actions, domestic and international sanctions, fraud by third parties or Users themselves and other factors beyond the control of UMI Walk. This also includes loss of access to third (third-party) services, including loss of access to accounts or any other data or equipment.'}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                        />
                    </div>
                    <AppText
                        className={cls.termsOfUseText}
                        text={'Please, note: the Application may contain links to third-party web resources at one time or another. UMI Walk bears no relation to these web resources and does not manage them in any way. UMI Walk does not comment on the content of these resources and shall not be liable for such content. Users shall navigate to a third-party resource from the Application of their own free will and shall be liable for any result of such transition, including negative consequences for both the User and their equipment (PC, tablet, smartphone, among others). Third-party web resources adhere to their own Terms and Rules which differ from the Terms and Rules in force in our Application. Thus, when visiting any web resource, you shall review the terms and rules regulating this resource.'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                </div>
                <div className={cls.termsOfUseBlock}>
                    <AppText
                        className={cls.termsOfUseTitleThird}
                        text={'Risks of using the Site, Application, third-party services and various assets'}
                        fontSize={20}
                        fontWeight={700}
                        lineHeight={24}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'By using the Site and Application, you represent and warrant that:'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                    <div className={cls.termsOfUseBlockSecondary}>
                        <AppText
                            className={cls.termsOfUseText}
                            text={'- fully understand the interaction mechanisms and have experience with various digital assets and blockchain-based products, infrastructures and services.'}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                        />
                        <AppText
                            className={cls.termsOfUseText}
                            text={'- fully understand the risks associated with storing, exchanging and trading digital assets of all kinds, including those related to blockchain technology.'}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                        />
                        <AppText
                            className={cls.termsOfUseText}
                            text={'- unless otherwise stated, you will not rent, lend, sell, transfer, distribute or sublicense your account or our services, digital items or assets in any form. Unless otherwise stated, you must not make our services available over a network where they may be used by multiple devices at the same time.'}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                        />
                        <AppText
                            className={cls.termsOfUseText}
                            text={'- by using the Site and App, you acknowledge and agree that any digital asset or product used in or outside of the UMI Walk guarantees absolutely no profit or revenue in any form.'}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                        />
                    </div>
                    <AppText
                        className={cls.termsOfUseText}
                        text={'BY USING THE SITE AND APP, YOU RECOGNIZE THAT ANY DIGITAL ASSETS LINKED TO BLOCKCHAIN TECHNOLOGY ARE EXTREMELY VOLATILE. THEIR MARKET EXCHANGE RATE (IF ANY) MAY FLUCTUATE OVER A WIDE RANGE AT DIFFERENT AND UNPREDICTABLE TIME INTERVALS (FLUCTUATIONS MAY OCCUR OVER A LONG PERIOD OF TIME, AS WELL AS OVER A SINGLE DAY OR HOUR, MINUTE, ETC.), WITH THE THEORETICAL POSSIBILITY OF DROPPING TO ZERO. ALSO, ANY DIGITAL ASSETS MAY AT ANY MOMENT COMPLETELY DISAPPEAR FROM THE GLOBAL ECOSYSTEM OR BE BANNED AT THE LEGISLATIVE LEVEL.'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'ALL OF THE FOREGOING IMPLIES A HIGH RISK OF LOSING ALL OF YOUR FUNDS (OR OTHER ASSETS) THAT YOU HAVE SPENT TO OBTAIN ANY DIGITAL ASSETS OR PRODUCTS RELATED TO BLOCKCHAIN TECHNOLOGY IN ANY WAY. AND THERE ARE ALSO RISKS OF TOTAL LOSS OF VALUE OF ANY ASSETS, INCLUDING THOSE DERIVED FROM THE USE OF THE SITE OR APPLICATION (IF APPLICABLE). YOU ACKNOWLEDGE THAT YOU ARE IN NO WAY INSURED AGAINST LOSS OR TOTAL LOSS OF FUNDS OR OTHER ASSETS, INCLUDING LOSS OF VALUE, WHEN USING THE SITE AND APPLICATION OR THIRD PARTY SERVICES IN CONNECTION WITH INTERACTIONS WITH ANY DIGITAL ASSETS AND PRODUCTS, INCLUDING THOSE RELATED TO BLOCKCHAIN TECHNOLOGY.'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'YOU AGREE NOT TO MAKE ANY CLAIM AGAINST UMI WALK IF YOU SUFFER ANY LOSS OR DAMAGE WHILE USING THE SITE AND APPLICATION FOR ANY REASON WHATSOEVER. YOU FURTHER REPRESENT AND WARRANT THAT YOU WILL NOT ENGAGE IN ANY TRANSACTIONS BEYOND YOUR FINANCIAL ABILITY, INCLUDING, BUT NOT LIMITED TO, USING THE SITE, APPLICATION, THIRD PARTY SERVICES OR INTERMEDIARIES TO BORROW MONEY IN ANY FORM, OR USING THE SITE AND APPLICATION WITHOUT SUFFICIENT EXPERTISE IN BLOCKCHAIN TECHNOLOGY PRODUCTS.\n'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                </div>
                <div className={cls.termsOfUseBlock}>
                    <AppText
                        className={cls.termsOfUseTitleThird}
                        text={'Limitation of liability'}
                        fontSize={20}
                        fontWeight={700}
                        lineHeight={24}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'The Site and Application are provided to Users on an "as is" and "as available" basis without any representations or warranties of any kind, express or implied.'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'The information on the Site and Application does not constitute in any way financial advice, recommendation or call for investment of funds or other assets of any kind. UMI Walk Team and its affiliates do not provide financial advice in any form. To obtain financial or any other form of advice, you should contact qualified professionals in your area of interest.'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'Any transactions, including those involving digital assets and blockchain-based products, or other transactions you make on the Site and Application, or outside the Site and Application, are solely at your own risk. You represent and warrant that you will not make any claims to the UMI Walk team and its affiliates in respect of any losses you may incur as a result of any transactions both on and off the Site and Application.'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'In no event shall UMI Walk its officers, shareholders, employees, agents, directors, subsidiaries, affiliates, successors, assigns, contacts, suppliers or licensors be liable for any loss or damage, including, but not limited to, direct, indirect, special, or punitive damages or losses, arising out of or in connection with your use of the Site and/or Application and whether or not such damages were foreseeable and whether or not the User has been advised of the possibility of such damages.'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'BY USING THE SITE AND THE APP, YOU FULLY UNDERSTAND AND ACCEPT FULL RESPONSIBILITY AND ASSUME ALL RISKS OF LOSS AND DAMAGE ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE SITE AND THE APP.'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'UMI WALK ASSUMES NO LIABILITY FOR ANY LOSS OR DAMAGE, INCLUDING ANY LOSS OF FUNDS, THAT MAY RESULT DIRECTLY OR INDIRECTLY FROM YOUR USE OF THE SITE AND APPLICATION, THIRD PARTY SERVICES, DIGITAL ASSETS AND BLOCKCHAIN-BASED PRODUCTS IN ANY WAY AND YOUR EXPECTATION OF A PARTICULAR RESULT FROM YOUR ACTIONS.'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'BY USING THE SITE AND APPLICATION, THIRD PARTY SERVICES, AND ANY DIGITAL ASSETS AND BLOCKCHAIN-BASED PRODUCTS OF ANY KIND, YOU FULLY UNDERSTAND AND ACCEPT THAT UMI WALK IS NOT RESPONSIBLE FOR ANY TRANSACTIONS THAT USERS MAKE ON AND OFF THE SITE AND APPLICATION, AND YOU VOLUNTARILY AGREE TO BE SOLELY RESPONSIBLE FOR ALL RISKS THAT MAY ARISE FROM ANY TRANSACTIONS AND ACTIONS YOU MAY TAKE WITH ANY DIGITAL ASSETS AND BLOCKCHAIN-BASED PRODUCTS OF ANY KIND.'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                </div>
                <div className={cls.termsOfUseBlock}>
                    <AppText
                        className={cls.termsOfUseTitleThird}
                        text={'Intellectual Property Rights'}
                        fontSize={20}
                        fontWeight={700}
                        lineHeight={24}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'You acknowledge and agree that the Site and Application are the property of UMI Walk or its licensors. Subject to your compliance with these Terms, Conditions and Risks, UMI Walk grants you a limited right to access and/or use the Site and Application in accordance with the purposes for which they were created by us.  All rights to software, graphics, textual content, design elements and other components of the Site and Application that are unique belong exclusively to UMI Walk or its licensors.'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'Nothing in these Terms, Conditions and Risks gives you any ownership rights in any unique component element of the Site and Application. You acknowledge and agree that UMI Walk retains all ownership rights and interests in all copyrights, trademarks, trade secrets, patents and any other proprietary rights in the Site and Application, software and application programming interfaces (APIs) and all unique content posted on the Site and Application.'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'You acknowledge and agree that our trademarks, service marks, logos and graphics are registered trademarks or trademarks of UMI Walk. You are prohibited from creating for commercial use fan art (tokenized or physical), merchandise, or any similar content related to the Site and Application And its affiliate links and projects.'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'If you create fan art for personal, non-commercial use, the artwork must clearly state "UMI Walk Fan Art" and must include a link to the Site or the UMI Walk App. We reserve the sole right to determine whether or not a product is fan art/goods.'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                </div>
                <div className={cls.termsOfUseBlock}>
                    <AppText
                        className={cls.termsOfUseTitleThird}
                        text={'Taxation'}
                        fontSize={20}
                        fontWeight={700}
                        lineHeight={24}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'The User is solely responsible for payment of any taxes applicable to him/her regarding his/her activities on the Website or in the Application, as well as for submission of relevant reports to the tax organization. For its part, UMI Walk does not prepare tax reports for Users and does not control Users with regard to their compliance with the tax legislation applicable to them. We, in turn, urge all Users to study all tax requirements and pay all necessary taxes.'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                </div>
                <div className={cls.termsOfUseBlock}>
                    <AppText
                        className={cls.termsOfUseTitleThird}
                        text={'Advertising Collaborations'}
                        fontSize={20}
                        fontWeight={700}
                        lineHeight={24}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'We may allow third-party companies to place their advertisements and other information in certain areas of the Site and App, including banners, pop-ups and sidebars.'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'However, the advertiser is solely and completely responsible for the content of its advertisements on the Site or Application And for any services provided on the Site or Application Or products sold through those advertisements.'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'Among other things, the advertiser warrants and represents that it has all rights and authority to advertise on the Site or App, including but not limited to intellectual property rights, advertising rights and contractual rights. For our part, we merely provide space for such advertisements and we have no other relationship with advertisers.'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                </div>
                <div className={cls.termsOfUseBlock}>
                    <AppText
                        className={cls.termsOfUseTitleThird}
                        text={'Arbitration'}
                        fontSize={20}
                        fontWeight={700}
                        lineHeight={24}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'In case of any disputable cases/situations regarding the Website, Application, Terms, Conditions, provisions and risks, the disputants shall settle such cases/situations within 7 days from the date of their occurrence. The parties to the dispute declare that they agree to conduct effective negotiations, the result of which will be an acceptable solution to the controversy for each of the parties to the dispute.'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'If no agreement on the dispute or controversy is reached within the 7-day period, the dispute or controversy may be referred to an arbitration tribunal for final decision on the basis of the then current Rules of Arbitration of the International Chamber of Commerce (ICC) (the "Rules") as amended herein. The seat of the arbitral tribunal shall be the City of Geneva, Switzerland. A request for arbitration shall be made in accordance with the Rules or the Terms, Conditions and Risks with notice. The arbitration shall be conducted in English and, accordingly, the decision of the arbitrators shall also be rendered in English.'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'The arbitration shall consist of three arbitrators. If there are only two parties to the arbitration, each party shall appoint one arbitrator on its own behalf and the third arbitrator shall be appointed by the ICC Court in accordance with the Rules. Where more than two parties are involved in the arbitration, the arbitrators shall be appointed in accordance with Article 10 of the Rules. At the request of any party to the arbitration, any arbitrator not timely appointed shall be appointed by the ICC in accordance with the Rules. The award rendered by the arbitral tribunal shall be final and binding on each party. However, no party to the arbitration, once the award has been made, shall have any right of appeal to the English courts, pursuant to section 45 of the Arbitration Act 1996, or appeal on any question of law, pursuant to section 69 of the Arbitration Act 1996. The award made by the arbitral tribunal shall therefore be the sole and exclusive remedy between the parties to the arbitration dispute in respect of any claim, counterclaim, issue or account submitted to the arbitral tribunal.'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'Any court having jurisdiction may render an award in any arbitration dispute. The parties agree that the ICC Court and/or the arbitral tribunal shall, at the request of any party to the arbitration, have the right to combine or consolidate two or more arbitration disputes arising out of or in connection with this agreement.'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'This agreement shall be governed by English law without giving effect to conflict of laws rules.'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                </div>
                <div className={cls.termsOfUseBlock}>
                    <AppText
                        className={cls.termsOfUseTitleThird}
                        text={'Contact Us'}
                        fontSize={20}
                        fontWeight={700}
                        lineHeight={24}
                    />
                    <div>
                        <AppText
                            className={cls.termsOfUseText}
                            text={'We are always available to answer any questions you may have regarding these Terms, Conditions and Risks, or any other questions you may have about the Application or the Site.\n' +
                                'You can send your letters to '}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                            tag={AppTextTag.SPAN}
                        />
                        <AppText
                            className={cls.termsOfUseText}
                            text={EMAIL}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.BLUE}
                            tag={AppTextTag.SPAN}
                        />
                    </div>
                </div>
                <div className={cls.termsOfUseBlock}>
                    <AppText
                        className={cls.termsOfUseTitleSecondary}
                        text={'2. UMI Walk Privacy Policy'}
                        fontSize={32}
                        fontWeight={700}
                        lineHeight={32}
                    />
                    <div>
                        <AppText
                            className={cls.termsOfUseText}
                            text={'We welcome you (hereinafter referred to as "User" or "you" including "your"/"yours" etc.) to the UMI Walk mobile gaming app, website ('}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                            tag={AppTextTag.SPAN}
                        />
                        <Link to={SITE_LINK} target={'_blank'}>
                            <AppText
                                className={cls.termsOfUseText}
                                text={SITE_LINK}
                                fontSize={14}
                                fontWeight={400}
                                lineHeight={20}
                                theme={AppTextTheme.GREY}
                                tag={AppTextTag.SPAN}
                            />
                        </Link>
                        <AppText
                            className={cls.termsOfUseText}
                            text={'), affiliated websites and social media (hereinafter referred to as "UMI Walk", "Site", "App" or "we" (including "our"/"ours" etc.)!'}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                            tag={AppTextTag.SPAN}
                        />
                    </div>

                </div>
                <div className={cls.termsOfUseBlock}>
                    <AppText
                        className={cls.termsOfUseTitleThird}
                        text={'General Information'}
                        fontSize={20}
                        fontWeight={700}
                        lineHeight={24}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'By using the Site and Application, you agree by default to the current Terms, Conditions, Terms and Risks (described in paragraph 1) and the terms of this Privacy Policy (described in the current paragraph 2).'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'The purpose of this Privacy Policy is to inform Users of the Site and Application about the information we collect, our actions with that information, and the safeguarding of that information. In any case, UMI Walk is committed to protecting the privacy of individuals and complies with all laws, principles and regulations applicable to the confidentiality of personal data of its Users.'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'By using the Site and Application (as defined in the Terms, Conditions and Risks), you consent to the collection and use of your personal data in accordance with the regulations of this Privacy Policy.'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                    <AppText
                        className={cls.termsOfUseTitleThird}
                        text={'By using the App, you consent to the collection and use of your personal data in accordance with the terms of this Privacy Policy. By using the Application, you agree to all provisions of the UMI Walk Application Privacy Policy.'}
                        fontSize={20}
                        fontWeight={700}
                        lineHeight={24}
                    />
                </div>
                <div className={cls.termsOfUseBlock}>
                    <AppText
                        className={cls.termsOfUseTitleThird}
                        text={'Who the Privacy Policy of the UMI Walk Application is Applied to'}
                        fontSize={20}
                        fontWeight={700}
                        lineHeight={24}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'This Privacy Policy applies to Users of the Site, UMI Walk Application, UMI Walk affiliated sites and any services provided by us.'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                </div>
                <div className={cls.termsOfUseBlock}>
                    <AppText
                        className={cls.termsOfUseTitleThird}
                        text={'Information UMI Walk Collects'}
                        fontSize={20}
                        fontWeight={700}
                        lineHeight={24}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'We only collect information that we need to provide services to Users and to protect Users in certain situations. This may include, without limitation, the following information:'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                    <div className={cls.termsOfUseBlockSecondary}>
                        <AppText
                            className={cls.termsOfUseText}
                            text={'- Your email, including your name and other data, if any. This data is collected when you register as a User, when you subscribe to our email newsletter (if available), and if you send your requests to our technical support using email.'}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                        />
                        <AppText
                            className={cls.termsOfUseText}
                            text={'- Any data you provide when making any transactions or actions. Including when you contact technical support/participate in certain social network drawings, etc.'}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                        />
                        <AppText
                            className={cls.termsOfUseText}
                            text={'- ID of your social media accounts. This data becomes available to us when you subscribe to our social media accounts and channels, contact us via social media, or share information on social media using the Application. All data you openly indicate in your social media, as well as all data you voluntarily provide as you correspond with us to resolve the issues you might have become available to us automatically. These may include: your full name, date of birth, age, nationality, gender, signature, phone number, home address, utility bills, passport number, driver\'s license data, national identity card data, photo ID, position, office location, health data, religious beliefs or affiliation, philosophical beliefs, criminal record, ethnic background, etc.'}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                        />
                        <AppText
                            className={cls.termsOfUseText}
                            text={'- Information about the transactions and activities you perform on the Site and/or App. Collecting information about transactions and activities makes it easier to identify any suspicious activities that could lead to fraud or any losses. It also improves security, allows us to more effectively combat spam and malware, and to detect and disarm bot programs. We need to be prepared to deal with any new risks that may arise. Without this information, it will be difficult for us to keep our service secure.'}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                        />
                        <AppText
                            className={cls.termsOfUseText}
                            text={'-Data may also be collected about your location (your geolocation) and your walking speed. Data may be collected using GPS, and the app may ask your permission to use it. This may be necessary to correctly determine the distance you have traveled and the speed at which you are moving.'}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                        />
                    </div>
                </div>
                <div className={cls.termsOfUseBlock}>
                    <AppText
                        className={cls.termsOfUseTitleThird}
                        text={'Log Data'}
                        fontSize={20}
                        fontWeight={700}
                        lineHeight={24}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'Like many other web resources, we collect the data that your browser automatically sends to us each time you visit the Site and/or Application ("Log Data"). This data may include the following information: the IP address of your device (with the last byte changed), the browser name and version, the pages you viewed on the Site and App, the date, time and duration of your visit and other statistical information.'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'The Site processes this information in order to study and analyze the behavioral patterns of Users and to obtain visitor statistics. Through these actions, we can optimize the Site and the Application to make them even more user-friendly.'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                </div>
                <div className={cls.termsOfUseBlock}>
                    <AppText
                        className={cls.termsOfUseTitleThird}
                        text={'Usage of cookies'}
                        fontSize={20}
                        fontWeight={700}
                        lineHeight={24}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'Cookies are small files that are used to identify you when you return to the Site or Application And to store the settings you have set on the Site or Application According to your personal preferences. The Site and Application Will pass cookies to the browser you use, after which your computer will store them on your hard disk. The information present in the cookie is detected by a server and may be used by that server each time you visit the Site and Application.'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'Most web browsers automatically accept cookies, but you may refuse to accept cookies by changing your browser settings yourself. However, doing so may prevent you from fully utilizing all features and functionality of the Site and Application.'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                </div>
                <div className={cls.termsOfUseBlock}>
                    <AppText
                        className={cls.termsOfUseTitleThird}
                        text={'Google Analytics'}
                        fontSize={20}
                        fontWeight={700}
                        lineHeight={24}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'Google Analytics is a plugin developed by Google that allows website owners to get useful traffic statistics as well as analyze user behavior. For this purpose, Google Analytics uses its own cookies, which are in no way related to the cookies we use.'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'The administration of UMI Walk does not have direct access to Google Analytics data, as Google provides us with the information collected through its own services.'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'The administration of UMI Walk may share the information it receives from Google Analytics with its partners. At the same time, this information does not include personal data of users.'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                    <div>
                        <AppText
                            className={cls.termsOfUseText}
                            text={'Each User of the Website and Application can block the possibility of data collection for Google Analytics at any time by connecting a special application from Google called Google Analytics opt-out by downloading it '}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                            tag={AppTextTag.SPAN}
                        />
                        <Link to={'https://tools.google.com/dlpage/gaoptout'} target={'_blank'}>
                            <AppText
                                className={cls.termsOfUseText}
                                text={'here'}
                                fontSize={14}
                                fontWeight={400}
                                lineHeight={20}
                                theme={AppTextTheme.BLUE}
                                tag={AppTextTag.SPAN}
                            />
                        </Link>
                        <AppText
                            className={cls.termsOfUseText}
                            text={'. Such blocking will not affect the practical use of the Site and the Application in any way. In addition, the User may use the "Do Not Track" (or other) function directly in the browser he uses to visit the Site and the Application to disconnect himself from the trackers present there.'}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                            tag={AppTextTag.SPAN}
                        />
                    </div>
                </div>
                <div className={cls.termsOfUseBlock}>
                    <AppText
                        className={cls.termsOfUseTitleThird}
                        text={'Who We Can Share Your Information With'}
                        fontSize={20}
                        fontWeight={700}
                        lineHeight={24}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'The information UMI Walk collects may be used or disclosed to the following persons for certain purposes:'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                    <div className={cls.termsOfUseBlockSecondary}>
                        <AppText
                            className={cls.termsOfUseText}
                            text={'- UMI Walk employees, as well as for various partners (individuals, legal entities and organizations), interacting with UMI Walk in one way or another, to provide Users with all kinds of services, as well as to solve any requests of Users sent to technical support.'}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                        />
                        <AppText
                            className={cls.termsOfUseText}
                            text={'- Legislative, executive, judicial and other officially authorized authorities and representatives thereof following an official order, resolution, or prescription within the applicable legislation. '}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                        />
                        <AppText
                            className={cls.termsOfUseText}
                            text={'- Special services, including cyber police, if we have grounds to suspect the User of involvement in cybercrime of any kind.'}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                        />
                    </div>
                </div>
                <div className={cls.termsOfUseBlock}>
                    <AppText
                        className={cls.termsOfUseTitleThird}
                        text={'General Data Protection Regulation (GDPR)'}
                        fontSize={20}
                        fontWeight={700}
                        lineHeight={24}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'General Data Protection Regulation (GDPR) is a regulation of the European Union (EU), which defines the procedure for processing personal data by organizations. Since UMI Walk provides services also to EU residents, we comply with the requirements of GDPR. This regulation is applied by us to all personal information that directly or indirectly identifies the User.'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'At the same time, all our employees, as well as contractors and partners with whom we work, sign a special confidentiality agreement to ensure the protection of any personal and other data of our Users.'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                </div>
                <div className={cls.termsOfUseBlock}>
                    <AppText
                        className={cls.termsOfUseTitleThird}
                        text={'Storage and Protection of Users\' Personal Information'}
                        fontSize={20}
                        fontWeight={700}
                        lineHeight={24}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'UMI Walk may store Users\' personal information at our facilities or service providers located around the world.'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'UMI Walk fully recognizes that the privacy of Users is extremely important. We have implemented numerous security measures to ensure that User data is as protected as possible from unauthorized access, alteration, disclosure and destruction. Among the security measures, among others, we utilize:'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                    <div className={cls.termsOfUseBlockSecondary}>
                        <AppText
                            className={cls.termsOfUseText}
                            text={'- data encryption and Secure Socket Layer (SSL) protocol, which is an advanced technology in the field of full data encryption;'}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                        />
                        <AppText
                            className={cls.termsOfUseText}
                            text={'- IT security measures, including password protection of databases, site monitoring, firewalls and so on.'}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                        />
                        <AppText
                            className={cls.termsOfUseText}
                            text={'- password encryption with SHA256 algorithm.'}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                        />
                    </div>
                    <AppText
                        className={cls.termsOfUseText}
                        text={'However, despite the measures we take, the protection of personal data is never completely reliable when it comes to the use of the Internet or wireless connections, including e-mail, telephone or SMS, as we have no way of protecting this information once it is sent and until we receive it. We cannot guarantee 100% security of your personal information and we remind you that the User, on his/her part, should take a responsible approach to the safety of his/her personal data and ensure that it is properly protected from unauthorized use by third parties. If you want to contact us about the security of storage of your personal data collected by UMI Walk, write to the e-mail specified in the "Contact Us" section of this Privacy Policy.'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                </div>
                <div className={cls.termsOfUseBlock}>
                    <AppText
                        className={cls.termsOfUseTitleThird}
                        text={'Right to Access, Change, Delete or Restrict Processing of Personal Information'}
                        fontSize={20}
                        fontWeight={700}
                        lineHeight={24}
                    />
                    <div className={cls.termsOfUseBlockSecondary}>
                        <AppText
                            className={cls.termsOfUseText}
                            text={'- You have the right to review, adjust or change your personal information or delete this information if it is incorrect. '}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                        />
                        <AppText
                            className={cls.termsOfUseText}
                            text={'- You have the right to withdraw your consent to the processing of your personal information, but if you do so, it will not be possible to use the Site and/or Application.'}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                        />
                        <AppText
                            className={cls.termsOfUseText}
                            text={'- You have the right to demand that UMI Walk provide you with a copy of your personal information we store. This information will be provided without undue delay for a fee for collection of information (in accordance with the law) unless such provision infringes on the rights and freedoms of other persons.'}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                        />
                        <AppText
                            className={cls.termsOfUseText}
                            text={'- You have the right to demand that UMI Walk update any of your personal information that you consider to be incorrect or inaccurate; you can also confirm the accuracy of the data that needs to be updated.'}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                        />
                        <AppText
                            className={cls.termsOfUseText}
                            text={'- You have the right to request the deletion of your personal information if it is no longer required for the purposes for which it was collected or otherwise processed, and if such deletion is not inconsistent with applicable law and the terms of this Privacy Policy. However, depending on the type of information deleted, in some cases it may not be possible to use the Site and/or Application.'}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                        />
                    </div>
                </div>
                <div className={cls.termsOfUseBlock}>
                    <AppText
                        className={cls.termsOfUseTitleThird}
                        text={'Right to Restrict or Continue Processing of Personal Information'}
                        fontSize={20}
                        fontWeight={700}
                        lineHeight={24}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'You have the right to restrict or object to our processing of your personal information if any of the following conditions apply:'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                    <div className={cls.termsOfUseBlockSecondary}>
                        <AppText
                            className={cls.termsOfUseText}
                            text={'- You dispute the accuracy of your personal information that we have processed. In such cases, we will restrict processing for the period necessary for us to verify on our part the accuracy of your personal information and to obtain new, clarifying information from you. During this period, your access to the Site and/or Application May be suspended.'}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                        />
                        <AppText
                            className={cls.termsOfUseText}
                            text={'- The processing of your personal information is, in your opinion, unlawful. In such cases, we will also restrict processing for the period necessary for you to substantiate your opinion in a legal context. During this period, your access to the Site and/or Application May be suspended.'}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                        />
                        <AppText
                            className={cls.termsOfUseText}
                            text={'- We no longer need your personal information for processing purposes, but you need it to establish, implement or protect legal requirements. In such cases, you have the right to send us a request to continue processing your personal information.'}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                        />
                    </div>
                    <AppText
                        className={cls.termsOfUseText}
                        text={'IF YOUR ACCESS TO THE SITE AND/OR APPLICATION IS SUSPENDED OR TERMINATED, WE WILL RETAIN YOUR INFORMATION IN OUR DATABASE FOR A MINIMUM OF SEVEN YEARS FOR REGULATORY PURPOSES.'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'THIS IS TO ENSURE THAT THOSE WHO TRY TO GET AWAY WITH ILLEGAL ACTIVITIES, SUCH AS FRAUD AMONG OTHERS, CANNOT DO SO BY SIMPLY DELETING THEIR ACCOUNT FROM THE SITE AND/OR APPLICATION. AT THE SAME TIME, IF YOU DELETE YOUR ACCOUNT FROM THE SITE AND/OR APP, BUT YOU ARE NOT A LAWBREAKER, YOUR INFORMATION WILL NOT BE USED BY US FOR ANY FURTHER PURPOSE, INCLUDING TRANSFER TO THIRD PARTIES.'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                    <AppText
                        className={cls.termsOfUseText}
                        text={'IF YOU BELIEVE THAT UMI WALK HAS VIOLATED YOUR RIGHTS, WE ASK THAT YOU CONTACT THE APPROPRIATE AUTHORITIES AND SEEK ADVICE BEFORE FILING A COMPLAINT SO THAT WE CAN ATTEMPT TO RESOLVE THE DISPUTE.'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={20}
                        theme={AppTextTheme.GREY}
                    />
                </div>
                <div className={cls.termsOfUseBlock}>
                    <AppText
                        className={cls.termsOfUseTitleThird}
                        text={'Contact Us '}
                        fontSize={20}
                        fontWeight={700}
                        lineHeight={24}
                    />
                    <div>
                        <AppText
                            className={cls.termsOfUseText}
                            text={'If you have any questions about this Privacy Policy or the handling of your personal information, you may contact us at '}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.GREY}
                            tag={AppTextTag.SPAN}
                        />
                        <AppText
                            className={cls.termsOfUseText}
                            text={EMAIL}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={20}
                            theme={AppTextTheme.BLUE}
                            tag={AppTextTag.SPAN}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

